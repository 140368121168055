import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService extends Parse.Object {

  constructor() {
    super('AppConfig');
  }

  load(): Promise<AppConfigService> {
    return Parse.Cloud.run('getAppConfig');
  }

  get about(): any {
    return this.get('about');
  }

  get admin(): any {
    return this.get('admin');
  }

  get layout(): any {
    return this.get('layout');
  }

  get auth(): any {
    return this.get('auth');
  }

  get ios_latest(): any{
    return this.get('ios_latest');
  }

  get android_latest(): any{
    return this.get('android_latest');
  }

  get home_view_config(): any{
    return this.get('home_view_config');
  }

  get shares(): any{
    return this.get('shares');
  }

  get update_type(): any{
    return this.get('update_type');
  }

  get bnpl(): any{
    return this.get('bnpl');
  }

  get biit_bank_accounts(): any{
    return this.get('biit_bank_accounts');
  }
}

Parse.Object.registerSubclass('AppConfig', AppConfigService);