import { Component, Injector, NgZone } from '@angular/core';
import { isPlatform } from '@ionic/angular';
import { User } from './services/user';
import { environment } from 'src/environments/environment';
import { Category } from './services/category';
import { Item } from './services/item';
import { Card } from './services/card';
import { Cart } from './services/cart';
import { LocalStorage } from './services/local-storage';
import { Installation } from './services/installation';
import * as Parse from 'parse';
import { Slide } from './services/slide';
import { StripeService } from 'ngx-stripe';
import { AudioService } from './services/audio';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import OneSignal, { OneSignalPlugin } from 'onesignal-cordova-plugin';
import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import Utils from './utils/utils';
import { nanoid } from 'nanoid';
import { BasePage } from './pages/base-page/base-page';
import { NotificationPage } from './pages/notification/notification.page';
import { register } from 'swiper/element/bundle';
register();

declare global {
  interface Window {
    FB: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent extends BasePage {

  public user: User;
  public latest_version: string;
  public showUpdateModal: boolean = false;

  private cartCount = '';

  constructor(injector: Injector,
    private cartService: Cart,
    private localStorage: LocalStorage,
    private userService: User,
    private installationService: Installation,
    private stripeService: StripeService,
    private audioService: AudioService,
    private ngZone: NgZone) {
    super(injector);
    this.initializeApp();
  }

  enableMenuSwipe(): boolean {
    return true;
  }

  async initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZone.run(() => {
          // Example url: https://app.biiteq.com/1/home/
          // slug = /tabs/tab2

          const slug = event.url.split(".com").pop();
          console.log("+++++++++++++++++++++++++++In Initialise App++++++++++++++++++++++++++++++++++++++"+slug);
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
    });


    this.translate.setDefaultLang(environment.defaultLang);

    this.setupParse();
    this.setupEvents();

    if (Capacitor.isNativePlatform()) {
      this.setupStatusBar();
      // this.setupNativeAudio();
      await Utils.sleep(2000);
      SplashScreen.hide();
    }

    this.loadUser();
  }

  async loadUser() {

    try {

      this.showLoadingView({ showOverlay: false });

      const user = User.getCurrent();

      if (!user) {
        const { sessionToken } = await this.userService.loginAnonymously();
        await this.userService.becomeWithSessionToken(sessionToken);
      }

      await this.setupDefaults();

      if (Capacitor.isNativePlatform()) {
        this.setupPush();
        this.setupOneSignal();
      }

      this.updateInstallation();
      this.loadCart();
      this.showContentView();

    } catch (error) {
      this.showErrorView();
    }
  }

  setupParse() {
    (Parse as any).serverURL = environment.serverUrl;
    Parse.initialize(environment.appId);
    (Parse as any).idempotency = true;

    Slide.getInstance();
    Category.getInstance();
    Item.getInstance();
    Card.getInstance();
  }

  setupNativeAudio() {

    let path = 'pristine.mp3';

    if (isPlatform('ios')) {
      path = 'public/assets/audio/pristine.mp3';
    }

    this.audioService.preload('ping', path);
  }

  async setupDefaults() {

    try {

      const supportedLangs = ['en', 'es', 'ar'];
      const browserLang = navigator.language.substr(0, 2);

      const promises: any = [
        this.localStorage.getLang(),
        this.localStorage.getIsPushEnabled(),
      ];

      let [lang, isPushEnabled]: any = await Promise.all(promises);

      if (lang === null && supportedLangs.indexOf(browserLang) !== -1) {
        lang = browserLang;
      }

      lang = lang || environment.defaultLang;

      if (lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }

      this.localStorage.setLang(lang);
      this.translate.use(lang);
      this.preference.lang = lang;

      this.preference.isPushEnabled = isPushEnabled;

    } catch {
      this.preference.lang = environment.defaultLang;
    }

  }

  setupEvents() {
    window.addEventListener('cart:updated', (e: CustomEvent) => {
      this.updateCartCount(e.detail);
    });

    window.addEventListener('cart:expired', (e: CustomEvent) => {
      const cart = e.detail;
      this.updateCartCount(cart);
      cart.save({ status: 'active' });
      this.translate.get('CART_EXPIRED')
        .subscribe(str => this.showToast(str));
    });

    window.addEventListener('user:login', (e: CustomEvent) => {
      this.user = e.detail;
      this.updateInstallation();
      this.loadCart();
    });

    window.addEventListener('user:logout', (e: CustomEvent) => {
      this.logout();
    });

    window.addEventListener('lang:change', (event: CustomEvent) => {
      this.onChangeLang(event.detail);
    });

    window.addEventListener('installation:update', (event: CustomEvent) => {
      this.updateInstallation(event.detail);
    });
  }

  async onChangeLang(lang: string) {
    try {
      await this.updateInstallation({ localeIdentifier: lang });
      await this.localStorage.setLang(lang);
    } catch (error) {
      console.log(error);
    }

    window.location.reload();
  }

  setupStatusBar() {
    if (isPlatform('ios')) {
      StatusBar.setStyle({ style: Style.Light });
    } else {
      StatusBar.setBackgroundColor({
        color: environment.androidHeaderColor
      });
    }
  }

  async loadCart() {

    try {

      let cart = await this.cartService.getOne();
      cart = cart || new Cart;
      this.updateCartCount(cart);

    } catch (error) {
      console.log(error);

      if (error.code === 209) {
        this.logout({ silent: true });
      }
    }
  }

  updateCartCount(cart: Cart) {
    this.cartCount = cart.items.length;
    this.preference.cartCount = this.cartCount;
  }

  setupOneSignal() {

    const appId = environment?.oneSignal?.appId;
    console.log('One signal '+appId)
    OneSignal.setLogLevel(6,0);

    if (appId) {

      OneSignal.setAppId(appId);
      
      OneSignal.setNotificationWillShowInForegroundHandler((event: NotificationReceivedEvent) => {

        const notification = (event as any).notification
        console.log('[ONE_SIGNAL] push received', notification);

        const notificationData: any = {
          ...notification.additionalData
        };

        notificationData.title = notification.title;
        notificationData.body = notification.body;
        notificationData.image_url = notification.bigPicture;

        if (this.platform.is('ios') &&
          typeof notification.attachments === 'object' &&
          notification.attachments !== null) {
          for (const [key, value] of Object.entries(notification.attachments)) {
            notificationData.image_url = value;
          }
        }

        this.presentNotificationModal(notificationData);

        this.audioService.play('ping');

        event.complete(null);
      });

      OneSignal.setNotificationOpenedHandler((res: OpenedEvent) => {
        console.log('[ONE_SIGNAL] push opened', res);

        const notificationData: any = res.notification.additionalData;

        let page = null;
        let queryParams = {};

        if (notificationData.brandId) {
          page = '/1/home/items';
          queryParams = { brand: notificationData.brandId };
        } else if (notificationData.categoryId) {
          page = '/1/home/items';
          queryParams = { cat: notificationData.categoryId };
        } else if (notificationData.subcategoryId) {
          page = '/1/home/items';
          queryParams = { subcat: notificationData.subcategoryId };
        } else if (notificationData.itemId) {
          page = '/1/home/items/' + notificationData.itemId;
          queryParams = {};
        }

        if (page) {
          this.ngZone.run(() => {
            this.router.navigate([page], { queryParams });
          });
        }

      });

      OneSignal.getDeviceState((data)=>{
        console.log(JSON.stringify(data.userId, null, 2))
      })
    }
  }

  async presentNotificationModal(notification: any) {

    const modal = await this.modalCtrl.create({
      component: NotificationPage,
      componentProps: { notification }
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {

      let page = null;
      let queryParams = {};

      if (data.brandId) {
        page = '/1/home/items';
        queryParams = { brand: data.brandId };
      } else if (data.categoryId) {
        page = '/1/home/items';
        queryParams = { cat: data.categoryId };
      } else if (data.subcategoryId) {
        page = '/1/home/items';
        queryParams = { subcat: data.subcategoryId };
      } else if (data.itemId) {
        page = '/1/home/items/' + data.itemId;
        queryParams = {};
      }

      this.router.navigate([page], { queryParams });
    }
  }

  setupPush() {
    console.log("setting up push")
    PushNotifications.addListener('registration', async (token: Token) => {

      console.log('registration: ' + token.value);

      const appInfo = await App.getInfo();
      console.log("App Information")
      console.log(appInfo)

      const info = await Device.getInfo();
      console.log(info)

      const languageCode = await Device.getLanguageCode();
      const id = await this.localStorage.getInstallationObjectId();

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const installationId = nanoid();

      const data: any = {
        channels: [],
        deviceToken: token.value,
        appName: appInfo.name,
        appVersion: appInfo.version,
        appIdentifier: appInfo.id,
        deviceType: info.platform,
        localeIdentifier: this.preference.lang || languageCode.value,
        timeZone: timezone,
        badge: 0,
      }

      if (!id) {
        data.installationId = installationId;
      }

      const user = User.getCurrent();
      data.user = user?.toPointer();

      const { objectId } = await this.installationService.save(id, data);

      await this.localStorage.setInstallationObjectId(id || objectId);

      const installation = await this.installationService.getOne(id || objectId);

      const granted: PermissionState = 'granted';
      const { receive } = await PushNotifications.checkPermissions();

      if (receive === granted && installation.isPushEnabled) {
        this.localStorage.setIsPushEnabled(true);
        this.preference.isPushEnabled = true;
      }

    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('Push received: ' + JSON.stringify(notification));

      if (environment.oneSignal?.appId) {
        return;
      }

      let notificationData = notification.data;

      if (isPlatform('android')) {
        notificationData = JSON.parse(notification.data.data);
        console.log('getting here');
        console.log(notificationData)
      }

      this.presentNotificationModal(notificationData);
      this.audioService.play('ping');
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(action));

      if (environment.oneSignal?.appId) {
        return;
      }

      let notificationData = action.notification.data;

      if (isPlatform('android')) {
        notificationData = JSON.parse(action.notification.data.data);
      }

      let page = null;
      let queryParams = {};

      if (notificationData.brandId) {
        page = '/1/home/items';
        queryParams = { brand: notificationData.brandId };
      } else if (notificationData.categoryId) {
        page = '/1/home/items';
        queryParams = { cat: notificationData.categoryId };
      } else if (notificationData.subcategoryId) {
        page = '/1/home/items';
        queryParams = { subcat: notificationData.subcategoryId };
      } else if (notificationData.itemId) {
        page = '/1/home/items/' + notificationData.itemId;
        queryParams = {};
      }

      if (page) {
        this.ngZone.run(() => {
          this.router.navigate([page], { queryParams });
        });
      }
    });
  }

  async updateInstallation(data: any = {}) {

    try {

      if (Capacitor.isNativePlatform()) {

        const payload: any = {
          user: null,
          ...data,
        };

        const id = await this.localStorage.getInstallationObjectId();

        if (!id) {
          return;
        }

        const user = User.getCurrent();
        payload.user = user?.toPointer();

        const res = await this.installationService.save(id, payload);
        console.log('Installation updated', res);
      }

    } catch (error) {
      console.log(error);
    }

  }

  async logout(ev: any = {}) {

    try {

      if (!ev.silent) {
        const str = await this.translate
          .get('LOGOUT_CONFIRMATION').toPromise();
        const confirm = await this.showConfirm(str);

        if (!confirm) {
          return;
        }
      }

      await this.showLoadingView({ showOverlay: true });
      await this.userService.logout();
      if (Capacitor.isNativePlatform()) {
        await this.updateInstallation();
      }
      await this.dismissLoadingView();

      window.location.reload();

    } catch (err) {

      if (err.code === 209) {
        window.location.reload();
      }

      this.dismissLoadingView();
    }
  }
}
