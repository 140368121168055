<ion-app>
  <div *ngIf="isLoadingViewVisible" class="flex full-height ion-align-items-center ion-justify-content-center">
    <ion-spinner name="crescent" color="dark"></ion-spinner>
  </div>
  
  <empty-view *ngIf="isErrorViewVisible" icon="alert-circle-outline" [text]="'ERROR_NETWORK'">
    <ion-button (click)="loadUser()">
      {{ 'TRY_AGAIN' }}
    </ion-button>
  </empty-view>
  <ion-router-outlet *ngIf="isContentViewVisible"></ion-router-outlet>
</ion-app>