import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
  }

  async init() {

    if (this._storage) {
      return this._storage;
    }

    const storage = await this.storage.create();
    this._storage = storage;

    return this._storage;
  }

  async getLang(): Promise<string> {
    await this.init();
    return this.storage.get('lang');
  }

  async setLang(val: string) {
    await this.init();
    return this.storage.set('lang', val);
  }

  async getIsPushEnabled(): Promise<boolean> {
    await this.init();
    return this.storage.get('isPushEnabled');
  }

  async setIsPushEnabled(val: boolean) {
    await this.init();
    return this.storage.set('isPushEnabled', val);
  }

  async setInstallationObjectId(val: string): Promise<string> {
    await this.init();
    return this._storage?.set('installationObjectId', val);
  }

  async getInstallationObjectId(): Promise<string> {
    await this.init();
    return this._storage?.get('installationObjectId');
  }

}
