import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { ParseQueryParams } from '../interfaces/query.interface';
import { Subject } from 'rxjs';

export enum ParseUserType {
  super_admin = 'super_admin',
  admin = 'admin',
  customer = 'customer',
};

export interface ParseUserQueryParams extends ParseQueryParams {
  type?: string;
  canonical?: string;
  anonymous?: boolean;
  orderBy?: string,
  orderByField?: string,
};

@Injectable({
  providedIn: 'root'
})
export class User extends Parse.User {

  constructor() {
    super();
  }

  private userCreatedSource = new Subject<Parse.User>();
  private userDestroyedSource = new Subject<Parse.User>();
  private userSessionExpiredSource = new Subject<boolean>();
  private userSessionCreatedSource = new Subject<boolean>();
  private userSessionDestroyedSource = new Subject<boolean>();

  userCreated$ = this.userCreatedSource.asObservable();
  userDestroyed$ = this.userDestroyedSource.asObservable();
  userSessionExpired$ = this.userSessionExpiredSource.asObservable();
  userSessionCreated$ = this.userSessionCreatedSource.asObservable();
  userSessionDestroyed$ = this.userSessionDestroyedSource.asObservable();

  onUserCreated(user: Parse.User) {
    this.userCreatedSource.next(user);
  }

  onUserDestroyed(user: Parse.User) {
    this.userDestroyedSource.next(user);
  }

  onUserSessionExpired() {
    this.userSessionExpiredSource.next(true);
  }

  onUserSessionCreated() {
    this.userSessionCreatedSource.next(true);
  }

  onUserSessionDestroyed() {
    this.userSessionDestroyedSource.next(true);
  }

  find(params: any = {}): Promise<{ total: number, users: Parse.User[] }> {
    return Parse.Cloud.run('getUsers', params);
  }


  isCustomer(): boolean {
    return this.type === ParseUserType.customer;
  }

  isAdmin(): boolean {
    return this.type === ParseUserType.admin;
  }

  isSuperAdmin(): boolean {
    return this.type === ParseUserType.super_admin;
  }

  static getInstance() {
    return this;
  }

  static getCurrent() {
    return User.current() as User;
  }

  isAnonymous(): boolean {
    return this.authData && this.authData['anonymous'];
  }

  isLoggedInViaPassword() {
    return !this.authData;
  }

  loginAnonymously(): Promise<{ sessionToken: string }> {
    return Parse.Cloud.run('createAnonymousUser');
  }

  becomeWithSessionToken(sessionToken: string): Promise<any> {
    return User.become(sessionToken);
  }

  loginWith(provider: string, authData: any = {}, extraData: any = {}): Promise<User> {
    const user: any = new User;
    user.set(extraData);
    return user._linkWith(provider, authData);
  }

  loginInCloud(data: any = {}): Promise<{ sessionToken: string }> {
    return Parse.Cloud.run('loginInCloud', data);
  }

  signUpInCloud(data: any = {}): Promise<{ sessionToken: string }> {
    return Parse.Cloud.run('signUpInCloud', data);
  }

  signIn(data: any = {}): Promise<User> {
    const user = new User;
    user.username = data.username;
    user.password = data.password;
    return user.logIn();
  }

  recoverPassword(email: string) {
    return Parse.User.requestPasswordReset(email);
  }

  logout() {
    return Parse.User.logOut();
  }

  get name(): string {
    return this.get('name');
  }

  set name(val) {
    this.set('name', val);
  }

  get email(): string {
    return this.get('email');
  }

  set email(val) {
    this.set('email', val);
  }

  get phone(): string {
    return this.get('phone');
  }

  set phone(val) {
    this.set('phone', val);
  }

  get username(): string {
    return this.get('username');
  }

  set username(val) {
    this.set('username', val);
  }

  get password(): string {
    return this.get('password');
  }

  set password(val) {
    this.set('password', val);
  }

  get recentlyViewed(): string[] {
    return this.get('recentlyViewed') || [];
  }

  set recentlyViewed(value: string[]) {
    this.set('recentlyViewed', value);
  }

  get user_bnpl_rating(): number {
    return this.get('user_bnpl_rating') || 0;
  }

  set user_bnpl_rating(value: number) {
    this.set('user_bnpl_rating', value);
  }

  get authData(): any {
    return this.get('authData');
  }

  set authData(val) {
    this.set('authData', val);
  }

  get photo(): any {
    return this.get('photo');
  }

  set photo(val) {
    this.set('photo', val);
  }

  get contactEmail(): string {
    return this.get('contactEmail');
  }

  get type(): string {
    return this.get('type');
  }

  set type(val: string) {
    this.set('type', val);
  }

}

Parse.Object.registerSubclass('_User', User);
