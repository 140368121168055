import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { ParseQueryParams, ParseQueryResult } from '../interfaces/query.interface';

@Injectable({
  providedIn: 'root'
})
export class Category extends Parse.Object {

  constructor() {
    super('Category');
  }

  static getInstance() {
    return this;
  }

  load(params: any = {}): Promise<Category[]> {

    const query = new Parse.Query(Category);

    if (params.canonical) {
      query.contains('canonical', params.canonical);
    }

    query.equalTo('status', 'Active');
    query.ascending('name');
    query.doesNotExist('deletedAt');

    return query.find()
  }

  async find(params: ParseQueryParams): Promise<ParseQueryResult<Category>> {
    const query = new Parse.Query(Category);

    if (params?.query) {
      query.contains('canonical', params.query.toLowerCase());
    }

    if (params?.exclude) {
      query.notContainedIn('objectId', params.exclude);
    }

    if (params?.limit) {
      query.limit(params.limit);
    }

    if (params?.limit && params?.page) {
      const skip = params.limit * (params.page - 1);
      query.skip(skip);
    }

    query.descending('createdAt');

    if (params?.sort?.direction === 'asc') {
      query.ascending(params.sort?.field);
    } else if (params?.sort?.direction === 'desc') {
      query.descending(params.sort?.field);
    }

    query.withCount();
    query.doesNotExist('deletedAt');

    return query.find() as ParseQueryResult<Category>;
  }

  get name(): string {
    return this.get('name');
  }

  get status(): string {
    return this.get('status');
  }

  get slug(): string {
    return this.id + '/' + (this.get('slug') || '');
  }

  get image(): any {
    return this.get('image');
  }

  get imageThumb(): any {
    return this.get('imageThumb');
  }

  get subCategoryCount(): any {
    return this.get('subCategoryCount');
  }

}

Parse.Object.registerSubclass('Category', Category);