import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Category } from './category';
import { ParseQueryParams, ParseQueryResult } from '../interfaces/query.interface';

export interface SubCategoryQueryParams extends ParseQueryParams {
  categories?: Category[] | Parse.Pointer[];
}

@Injectable({
  providedIn: 'root'
})

export class SubCategory extends Parse.Object {

  constructor() {
    super('SubCategory');
  }

  load(params: any = {}): Promise<SubCategory[]> {

    const query = new Parse.Query(SubCategory);

    if (params.category) {
      query.equalTo('category', params.category);
    }

    if (params.canonical) {
      query.contains('canonical', params.canonical);
    }

    query.equalTo('status', 'Active');
    query.include('category');
    query.ascending('name');
    query.doesNotExist('deletedAt');

    return query.find();
  }

  count(params: any = {}): Promise<number> {

    const query = new Parse.Query(SubCategory);

    if (params.category) {
      query.equalTo('category', params.category);
    }

    if (params.canonical) {
      query.contains('canonical', params.canonical);
    }

    query.equalTo('status', 'Active');
    query.doesNotExist('deletedAt');

    return query.count();
  }

  async find(params: SubCategoryQueryParams): Promise<ParseQueryResult<SubCategory>> {
    const query = new Parse.Query(SubCategory);

    if (params?.query) {
      query.contains('canonical', params.query.toLowerCase());
    }

    if (params?.status) {
      query.equalTo('status', params.status);
    }

    if (params?.categories?.length) {
      query.containedIn('category', params.categories);
    }

    if (params?.exclude) {
      query.notContainedIn('objectId', params.exclude);
    }

    if (params?.limit) {
      query.limit(params.limit);
    }

    if (params?.limit && params?.page) {
      const skip = params.limit * (params.page - 1);
      query.skip(skip);
    }

    query.descending('createdAt');

    if (params?.sort?.direction === 'asc') {
      query.ascending(params.sort?.field);
    } else if (params?.sort?.direction === 'desc') {
      query.descending(params.sort?.field);
    }

    query.withCount();
    query.doesNotExist('deletedAt');
    query.include('category');

    return query.find() as ParseQueryResult<SubCategory>;
  }


  get name(): string {
    return this.get('name');
  }

  get status(): string {
    return this.get('status');
  }

  get category(): any {
    return this.get('category');
  }

  get imageThumb(): any {
    return this.get('imageThumb');
  }

  get image(): any {
    return this.get('image');
  }

  get slug(): string {
    return this.id + '/' + this.get('slug');
  }
}

Parse.Object.registerSubclass('SubCategory', SubCategory);